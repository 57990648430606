import React, { useEffect } from "react"
import styled from "styled-components"

import GlobalStyle from "../components/GlobalStyle"
import { FaInstagram, FaFacebookSquare } from "react-icons/fa"

import { Link } from "gatsby"

import Menu from "./HamburgerMenu"

import { Helmet } from "react-helmet"
import { MAILER_LITE_SCRIPT } from "../../mailerlitescript"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  // Extra small devices (portrait phones, less than 576px)
  font-size: 8px;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 8px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 12px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 14px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`
const Header = styled.div`
  padding: 2em;

  font-family: monument-bold, sans-serif;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  a {
    color: inherit;
  }

  .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;

    .contact-text {
      // Extra small devices (portrait phones, less than 576px)
      display: none;

      // Small devices (landscape phones, 576px and up)
      @media (min-width: 576px) {
        display: inherit;
      }
    }

    // Any child
    & > * {
      padding: 0px 6px;
      cursor: pointer;
    }
    & > *:hover {
      transform: scale(1.1);
    }
  }
`

const Title = styled.div`
  font-size: 1.5em;
  flex: 1;
  justify-content: left;
  a {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    text-decoration: none;
  }
`
const ContactLogo = styled.a`
  padding: 0em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 2em;
    height: 2em;
  }
`

const MenuWrapper = styled.div`
  padding: 0em 0.5em;
`

const ChildrenLayout = styled.div`
  padding: ${props => (props.fluid ? "0px" : "0px 1em")};
  flex: 1;

  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  align-items: flex-end;

  padding: 2em 0px 2em 0px;

  font-family: "Monument", sans-serif;
  font-size: 0.5em;
  text-align: center;
`

// We listen to the resize event
const calcVmin = () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01
  let vw = window.innerWidth * 0.01
  document.documentElement.style.setProperty("--vmin", `${vh > vw ? vw : vw}px`)
}

function Layout({ children, title, fluid = false }) {
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    let vw = window.innerWidth * 0.01
    document.documentElement.style.setProperty(
      "--vmin",
      `${vh > vw ? vw : vw}px`
    )
    // We listen to the resize event
    window.addEventListener("resize", calcVmin)

    return () => {
      window.removeEventListener("resize", calcVmin)
    }
  }, [])

  return (
    <Container>
      <Helmet>
        <script>{MAILER_LITE_SCRIPT}</script>
      </Helmet>
      <GlobalStyle />
      <Header>
        <Title>
          <Link to={"/"}>{title}</Link>
        </Title>
        <ContactLogo
          href="https://www.instagram.com/barskaphotostories/"
          className="instagram-logo logo"
        >
          <FaInstagram />
        </ContactLogo>
        <ContactLogo
          href="https://www.facebook.com/barskaphotostories"
          className="facebook-logo logo"
        >
          <FaFacebookSquare />
        </ContactLogo>
        <MenuWrapper>
          <Menu />
        </MenuWrapper>
      </Header>
      <ChildrenLayout fluid>{children}</ChildrenLayout>
      <Footer>
        <span>COPYRIGHT 2021 BARSKA PHOTO STORIES</span>
      </Footer>
    </Container>
  )
}

export default Layout
