import React, { useState } from "react"

import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  position: relative;
`

const Hamburger = styled.div`
  transition: 0.5 ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`
const HamburgerLine = styled.div`
  margin-bottom: 0.4em;

  &:first-child {
    margin-top: 0.4em;
  }

  width: 3em;
  border-bottom: 0.4em solid black;
`
const CloseClickCaptureDiv = styled.div`
  position: fixed;
  z-index: 1000000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`
const MenuContainer = styled.div`
  z-index: 1000001;
  position: absolute;
  top: 0px;
  right: 0px;

  height: 100%;

  background-color: #ffffffe0;
`
const MenuTitle = styled.div`
  font-size: 1em;
  text-transform: uppercase;
  margin: 30% 10% 15% 10%;
`

const MenuItem = styled.div`
  flex-shrink: 1;
  text-transform: uppercase;

  font-size: 1.5em;
  font-family: monument-bold, sans-serif;

  padding-bottom: 0.1em;
  margin: 1em 10%;

  a {
    text-decoration: none;
    line-height: 1.3;
    &:hover {
      border-bottom: 0.1em solid black;
    }
  }
`

const defaultItems = [
  {
    displayName: "Home",
    to: "/",
  },
  {
    displayName: "Barska Photo Stories",
    to: "/photography",
  },
  {
    displayName: "Portfolio",
    to: "/portfolio",
  },
  {
    displayName: "Barcelona Tours",
    to: "/tours",
  },
  {
    displayName: "Contact",
    to: "/contact",
  },
]

function HamburgerMenu({ items = defaultItems }) {
  const [isMenuOpen, setisMenuOpen] = useState(false)

  return (
    <Container>
      <Hamburger onClick={() => setisMenuOpen(!isMenuOpen)}>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
      </Hamburger>
      {isMenuOpen && (
        <CloseClickCaptureDiv onClick={() => setisMenuOpen(false)}>
          <MenuContainer>
            <MenuTitle>Dominka Barska</MenuTitle>
            {items.map(i => (
              <MenuItem onClick={() => console.log(i.displayName)}>
                <Link to={i.to}>{i.displayName}</Link>
              </MenuItem>
            ))}
          </MenuContainer>
        </CloseClickCaptureDiv>
      )}
    </Container>
  )
}

export default HamburgerMenu
