// globalStyles.ts

import { createGlobalStyle } from "styled-components"

import MonumentLight from "../../static/fonts/MonumentExtended-Ultralight.otf"
import Monument from "../../static/fonts/MonumentExtended-Regular.otf"
import MonumentBold from "../../static/fonts/MonumentExtended-Bold.otf"
import MonumentUltraBold from "../../static/fonts/MonumentExtended-Ultrabold.otf"

export const theme = {
  primaryWhite: "#fff",
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: monument-light;
    src: url(${MonumentLight}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: monument;
    src: url(${Monument}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: monument-bold;
    src: url(${MonumentBold}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: monument-ultra-bold;
    src: url(${MonumentUltraBold}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
`

export default GlobalStyle
